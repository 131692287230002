import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Switch,
  Redirect,
  Route,
  useLocation,
  matchPath,
} from 'react-router-dom';
import {
  QueryResult,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client';

import { useAuth } from 'phileog-login';
import { MutationCreateNode, QueryNodes } from 'phicomas-client';
import { User } from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import config from './config';
import routes from './customization/routes';

import SplashscreenHandler from './SplashscreenHandler';

import PreloadResources, {
  PreloadResourcesProps,
} from './lib/PreloadResources';

import AppWrapper from './lib/wrappers/AppWrapper';
import backgroundImage from './img/background.jpg';

import Login from './authComponents/Login';
import { MarkNonNullable } from './types/utils';
import { QUERY_ME, QUERY_ME_NAME } from './gql/customQueries';
import { MUTATION_CREATE_ME } from './gql/customMutations';
import App from './App';
import Forbidden from './authComponents/Forbidden';

type AuthUser = ReturnType<typeof useAuth>['user'];
function isLogged(authUser: AuthUser): authUser is NonNullable<AuthUser> {
  return authUser !== null;
}
function isAllowed(authUser: AuthUser): authUser is NonNullable<AuthUser> {
  if (!authUser) return false;
  const allowedCompany = ['SNCF VOYAGEURS', 'PHILEOG ELEVEN'].includes(
    authUser.company,
  );
  const allowedUser = [ '9818553R' , '7311896N' , '9402794D' , '9202242M' , '8402068H' , '7909410K' , '8806849M' , '8411164V' , '8411283Z' , '9202726N' , '9306665R' , '8712272L' , '7707786D' , '6809959E' , '8010000X' , '7505787P' , '9111783M' , '7705135X' , '8604955M' , '7009098K' , '6502162T' , '7806075P', '8311498M' , '7510775L' , '9305690F' , '9006908T' , '9701698Z' , '9112214F' , '8010690X' , '8207662X'].includes(authUser.sub); // see #95
  return allowedCompany || allowedUser;
}

type DataMe = QueryResult<QueryNodes<User>>['data'];
function isDataMeReady(
  dataMe: DataMe,
): dataMe is MarkNonNullable<NonNullable<DataMe>, typeof QUERY_ME_NAME> {
  return !!dataMe?.[QUERY_ME_NAME];
}

const AppLogNLoad: React.FC = () => {
  const apolloClient = useApolloClient();
  const { pathname, search, hash } = useLocation();

  const { user: authUser, loading: authLoading } = useAuth(config.credentials);

  const { data: dataMe, loading: loadingMe, error: errorMe } = useQuery<
    QueryNodes<User>
  >(QUERY_ME);
  const hasMe = isDataMeReady(dataMe);

  const userReady = isLogged(authUser) && isAllowed(authUser) && hasMe;
  const userNotAllowed = isLogged(authUser) && !isAllowed(authUser);

  const [createMe] = useMutation<MutationCreateNode<User>>(
    MUTATION_CREATE_ME.mutation,
  );
  useEffect(() => {
    if (isLogged(authUser) && isAllowed(authUser) && !loadingMe) {
      if (!dataMe || errorMe) {
        console.warn(
          `The '${QUERY_ME_NAME}' query returned an unexpected result`,
          { data: dataMe, error: errorMe },
        );
      } else if (!hasMe) {
        createMe({
          variables: { data: { sncfCP: authUser.sub } },
          update: MUTATION_CREATE_ME.update(apolloClient),
        });
      }
    }
  }, [authUser, dataMe, errorMe, loadingMe, createMe, apolloClient, hasMe]);

  const preloadResources = useMemo<PreloadResourcesProps['resources']>(
    () => [
      { resource: 'sncfFormTractionVideo', full: true },
      { resource: 'sncfFormTractionPost', full: true },
      { resource: 'sncfFormTractionTag', async: true },
    ],
    [],
  );

  const [previousUrl, setPreviousUrl] = useState<undefined | string>();
  useEffect(() => {
    if (pathname !== '/login') {
      setPreviousUrl(`${pathname}${search}${hash}`);
    }
  }, [hash, pathname, search]);

  const [splashscreenSeen, setSplashscreenSeen] = useState(false);
  const handleSplashscreenSeen = useCallback(() => {
    setSplashscreenSeen(true);
  }, []);

  const isOnVideoPage = !!matchPath(pathname, { path: routes.video });

  return (
    <AppWrapper backgroundImage={backgroundImage} fullHeight={isOnVideoPage}>
      <PreloadResources resources={preloadResources} wait={!userReady}>
        {({ ready, loaded }) => (
          <>
            <SplashscreenHandler
              show={(!ready || !splashscreenSeen) && !userNotAllowed}
              onSeen={handleSplashscreenSeen}
            />
            <Switch>
              <Route
                path="/login"
                render={props => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <Login {...props} previousUrl={previousUrl} />
                )}
              />
              {!authLoading && !isLogged(authUser) && <Redirect to="/login" />}
              {ready && splashscreenSeen && <App loading={!loaded} />}
              {isLogged(authUser) && !isAllowed(authUser) && (
                <>
                  <Route path="/forbidden" component={Forbidden} />
                  <Redirect to="/forbidden" />
                </>
              )}
            </Switch>
          </>
        )}
      </PreloadResources>
    </AppWrapper>
  );
};

export default React.memo(AppLogNLoad);
